import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

// breadcrumbs
const breadCrumbLevels = {
    Koti: "/",
    "Analytiikka Konsultointi": "/fi/analytiikka-konsultointi",
    "Google Analytics Asennus": "/fi/google-analytics-asennus"
  };
  
// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-analytics-setup"
);

  const AnalyticsSetup = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title={`Asenna Google Analytics alusta alkaen! [${currentYear} Opas]`}
          description="Opas nykyaikaisen Google Analytics -asetuksen määrittämisestä. Tavoitteiden asettaminen, tapahtumien seuranta ja parhaat käytännöt. Sertifioidun Google Analytics -konsultin laatima opas."
          lang="fi"
          image="google-analytics-setup-realtime-report-hero"
          alternateLangs={alternateLangs}
          canonical="/fi/google-analytics-asennus"
        />
        <MainContent article>
          <ImgScreenshot
            src="google-analytics-einrichten/google-analytics-setup-realtime-report-hero.png"
            alt="mies työskentelee Google Analytics -asetusten parissa kannettavalla tietokoneella"
          />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <H as="h1">Google Analytics Asennus Opas</H>

          <p>
  Yksi uuden verkkosivuston ensimmäisistä tehtävistä on Google Analyticsin asennus. Kaiken sen ajan ja vaivan jälkeen, jonka olet sijoittanut verkkosivuston rakentamiseen, haluat tietysti mitata ja analysoida käyttäjien käyttäytymistä. Saatat miettiä kysymyksiä kuten:
</p>

<ul>
  <li>Miten sisältöni suoriutuu?</li>
  <li>Mikä tuotekategoria on suosituin?</li>
  <li>Kuinka monta konversiota Facebook-kampanjani sai aikaan?</li>
  <li>Katsovatko kävijät oikeasti tuottamani videon?</li>
  <li>Onko sivuja, jotka suoriutuvat erityisen huonosti?</li>
  <li>Jakavatko kävijät mitään sosiaalisessa mediassa?</li>
  <li>Keskeyttävätkö ihmiset kassaprosessin aikana?</li>
</ul>

<p>
  Kaikki oletukset käyttäjien käyttäytymisestä sekä kampanjoiden suorituskyvystä on validoitava. <a href="https://w3techs.com/technologies/details/ta-googleanalytics" target="_blank">55 % kaikista internetin verkkosivustoista käyttää Google Analyticsia</a> tähän tarkoitukseen. Itse asiassa sillä on <a href="https://w3techs.com/technologies/overview/traffic_analysis" target="_blank">84 %:n markkinaosuus liikenteen analysointityökaluissa</a>, joten sinun ei tarvitse huolehtia väärän analytiikkatyökalun valinnasta.
</p>
<p>
  Verkkosivujen analytiikkatyökalu mahdollistaa päätösten tekemisen datan perusteella arvailujen sijaan. Tällaisia päätöksiä voivat olla esimerkiksi, mihin sisältötyyppiin kannattaa investoida tai kuinka paljon budjettia tulisi käyttää mainoskampanjoihin.
</p>
<p>Siksi on olennaisen tärkeää asettaa Google Analytics ammattimaisesti, jotta seuraat oikeita KPI-arvoja analyysiäsi varten. Tällaisen asetuksen avulla sinulla on käytettävissä runsaasti mittareita, joihin voit syventyä.
</p>
<H as="h2">Google Analytics -asetusten tyypit</H>
<p>
  Verkkosivuston tyyppi on keskeinen tekijä Google Analytics -asetuksissa:
  <br />
  Pyöritätkö verkkokauppaa tai myytkö tuotteita? <br />
  Silloin {/* <Link to="/fi/ecommerce-analytics"> */}
  e-commerce-analytiikka{/* </Link> */} on oikea valinta. Se on enemmän tuotteen ja transaktion suuntautunut ja antaa sinulle hyödyllistä tietoa, jota et halua menettää.
</p>
<p>
  Jos ylläpidät sisältöpainotteista sivustoa, joka käsittelee brändiäsi, niin tyypillinen Google Analytics -seurantajärjestelmä on oikea valinta. <br />
  Voit seurata käyttäjien vuorovaikutuksia <Link to="/fi/tapahtuman-seuranta">tapahtumien seurannalla</Link> ja seurata tärkeitä verkkosivuston KPI-arvoja <Link to="/fi/google-analytics-asennus">Google Analyticsin tavoitteiden</Link> avulla.
</p>

<p>
  E-commerce-analytiikkasetukset vaativat enemmän tietoa tuotteista ja transaktioista. Siksi konfiguraatio on hieman monimutkaisempi ja sisältää mukautetun JavaScriptin käytön niin sanotun <Link to="/fi/data-kerros">datalayerin</Link> rakentamiseksi.
  <br />Datalayer on olennaisesti tietovarasto, joka pitää sisällään tietoa jokaisesta sivusta. Google Analytics tunnistaa ja käyttää sitä, kun se on saatavilla.
</p>
<p>
  Älä huolehdi vielä, jos huomaat tarvitsevasi asettaa Google Analyticsin e-commerce-sivustolle. Voit aktivoida e-commerce-toiminnallisuuden myöhemmin ja parantaa datalayeriä, kun olet valmis siihen.
  <br />
  Siihen asti e-commerce-raportit pysyvät tyhjinä, mutta muut raportit toimivat hyvin.
</p>

<H as="h2">Mistä saada Google Analytics -seurantakoodi?</H>
<p>
  Käy osoitteessa{" "}
  <a href="https://analytics.google.com" target="_blank" rel="noopener">
    analytics.google.com
  </a>{" "}
  ja napsauta <strong>Rekisteröidy</strong>-painiketta luodaksesi Google Analytics -tilin. Saat Google Analytics -seurantakoodin, kun seuraat alla olevia vaiheita.
</p>
<ImgScreenshot
  src="google-analytics-setup/singup-for-google-analytics-tracking-code.png"
  alt="Google Analytics -rekisteröintinäyttökuva"
  className="article-img"
/>
<ul>
  <li>
    Napsautettuasi <strong>rekisteröidy</strong>-painiketta, jatka eteenpäin ja valitse omaisuusnimi ja URL-osoite sekä toimialasi ja aikavyöhykkeesi.
  </li>
  <li>
    Suosittelen <strong>poistamaan käytöstä kaikki tietojen jakamisasetukset</strong>, jos et ole täysin tietoinen tietosuojakäytännöstäsi. Mutta tämä tarkoittaa, että Google <strong>ei</strong> jaa kanssasi yleisödemografia- ja kiinnostusraportteja. <br />
    Jos haluat käyttää näitä raportteja, sinun on ehkä päivitettävä tietosuojakäytäntöäsi. Tämä on informoidaksesi käyttäjiä siitä, että jaat tietoa heidän selauskäyttäytymisestään Googlelle.
  </li>
  <li>
    Kun olet valmis, saat <strong>Google Analytics -seuranta-ID:si</strong> (esim. <em>UA-141112345-1</em>). Sen mukana saat koodinpätkän, joka tulee sijoittaa kaikkien sivujesi lähdekoodiin.
  </li>
</ul>
<H as="h2">Kuinka ottaa Google Analytics käyttöön?</H>
<p>Yleisesti ottaen on kaksi tapaa ottaa Google Analytics käyttöön:</p>

<ol>
  <li>
    Liitä Google Analytics -seurantakoodi kaikkien sivujen lähdekoodiin. Voit tehdä tämän sivu kerrallaan tai liittää sen yhteiseen mallipohjatiedostoon. Tämä voi olla header, navigaatio tai footer, joka on yhteinen kaikille sivuille.
    <br />
    <strong>En suosittele</strong> tätä tapaa toteuttaa Google Analyticsia, ellet halua tehdä sen nopeasti ja helposti, etkä koskaan koskea analytiikka-asetuksiin uudelleen. <br />
    Useimmat seurantajärjestelmät tehdään nopeammin ja helpommin <Link to="/fi/tagien-hallinta">taginhallintajärjestelmällä</Link>. Se on helpompi ja joustavampi prosessi, joten on järkevää ottaa käyttöön vielä <em>yksi työkalu</em> lisää.
  </li>

  <li>
    Käytä taginhallintajärjestelmää, kuten Google Tag Manager, joka on melko suosittu.
    <br />
    On olemassa myös muita vaihtoehtoja, kuten <em>Adobe Launch</em>, <em>Tealium</em>, <em>Ensighten</em> tai <em>Matomo</em> (avoin lähdekoodi). <br />
    Yleisesti ottaen ne kaikki palvelevat samaa tarkoitusta ja ovat hyvin samankaltaisia. Voit toteuttaa Google Analyticsin ei-Googlen taginhallintajärjestelmällä ilman huolta. Google Tag Manager on vain se, johon kaikilla on helppo pääsy ja josta on eniten resursseja saatavilla verkossa.
    <br />
    Jos haluat oppia lisää, tutustu <Link to="/fi/google-tag-manager-asennus">Google Tag Manager -oppaaseeni</Link>.
  </li>
</ol>

<H as="h2">Kuinka toteuttaa Google Analytics Google Tag Managerilla?</H>
<p>
  Mitä tulee <strong>Google Analyticsin toteuttamiseen Google Tag Managerin avulla,</strong> noudata näitä ohjeita:
</p>

<ul>
  <li>
    Käy osoitteessa{" "}
    <a href="https://tagmanager.google.com" target="_blank" rel="noopener">
      tagmanager.google.com
    </a>{" "}
    ja luo tili.{" "}
  </li>{" "}
</ul>
<ImgScreenshot
  src="google-analytics-setup/google-tag-manager-create-account.png"
  alt="Google Tag Manager -tilin luominen"
  className="article-img"
/>

<ul>
  <li>
    Tilin avulla luot säilön verkkosivustollesi, joten varmista, että valitset <strong>Web</strong> sen käyttötarkoitukseksi:
  </li>
</ul>

<ImgScreenshot
  src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
  alt="Google Tag Manager web -säilön luominen"
  className="article-img"
/>

<p>
  Sen jälkeen saat <strong>Google Tag Manager -koodinpätkän</strong> toteutusta varten:
</p>

<ImgScreenshot
  src="google-analytics-setup/google-tag-manager-code-snippet.png"
  alt="Google Tag Manager -koodinpätkä"
  className="article-img"
/>

<p>
  Lisää ensimmäinen osa <code>&lt;head&gt;</code>-tagiin kaikilla verkkosivustosi sivuilla. Toinen osa lisätään <code>&lt;body&gt;</code>-tagin sisälle.
</p>
<p>Kun Google Tag Manager (GTM) -pätkä on lisätty, analytiikka-asetuksen voi konfiguroida hetkessä!</p>

<ul>
  <li>
    GTM:ssä napsauta <strong>tags</strong> vasemmasta navigaatiosta ja <strong>lisää uusi tag</strong>. Ylätunniste liukuu sivulta ja voit antaa tagille <strong>nimen</strong>, esim. <em>Google Analytics -seuranta</em>.
  </li>
  <li>Sitten napsauta tagin määrityslaatikkoa:</li>

  <ImgScreenshot
  src="google-analytics-setup/google-tag-manager-create-new-tag.png"
  alt="Google Tag Managerin uuden tagin luominen"
  className="article-img"
/>

<li>
  Lista tagityypeistä liukuu esiin. <br />
  Valitse <strong>Google Analytics - Universal Analytics</strong> -tagi ja aloita määritys.
</li>

<ImgContainerFixed width="462px">
  <ImgScreenshot
    src="google-analytics-setup/google-tag-manager-google-analytics-setup-tag-selection.png"
    alt="Google Tag Managerin Google Analytics -tagin luominen"
    className="article-img"
  />
</ImgContainerFixed>

<li>
  Pidä <strong>Seurantatyyppi</strong> valittuna <strong>Sivun katselu</strong>. <br />
</li>
<li>
  Sitten <strong>luo uusi Google Analytics -asetusten muuttuja.</strong>
</li>

<ImgScreenshot
  src="google-analytics-setup/google-tag-manager-google-analytics-setup-settings.png"
  alt="Google Tag Manager -asetusten muuttuja Google Analyticsia varten"
  className="article-img"
/>

<li>
  Asetusten muuttujan määrittämiseksi anna sille ensin <strong>nimi</strong>. Sen jälkeen liitä <strong>Google Analytics -seuranta-ID</strong> (<em>UA-141112345-1</em>), jonka <Link to="#mistä-saada-google-analytics-seurantakoodi">sait aiemmin</Link>.
</li>

<ImgScreenshot
  src="google-analytics-setup/google-tag-manager-google-analytics-setup-tracking-id.png"
  alt="Google Tag Managerin Google Analytics -asennuksen seurantakoodi"
  className="article-img"
/>

<li>
  Klikkaa <strong>tallenna</strong>.
</li>
<li>
  Takaisin tagin määrityksessä, klikkaa <strong>laukaisu</strong>-osio valitaksesi laukaisimen.
</li>

<li>
  Valitse <strong>kaikki sivut</strong>, koska haluamme, että Google Analytics -koodinpätkä suoritetaan kaikilla sivuilla.
</li>

<li>
  <strong>Tallenna</strong> laukaisin sekä tagin määritys ja palaa Google Tag Managerin yleiskatsaukseen.
</li>

<li>
  Paina sinistä <strong>lähetä</strong>-painiketta oikeassa yläkulmassa. Anna ensimmäiselle säilöversiolle <strong>nimi</strong> ja klikkaa <strong>julkaise</strong>. <br />
  Nyt olet onnistuneesti toteuttanut Google Analyticsin Google Tag Managerin avulla!
</li>
</ul>

<p>
  Sinun pitäisi nähdä dataa tulevan Google Analytics -omaisuuteesi noin tunnin sisällä.
  <br />
  Voit myös katsoa reaaliaikaisesta raportista, onko nykyisestä vierailustasi jo alkanut tulla dataa.
</p>
<ImgScreenshot
  src="google-analytics-setup/google-analytics-realtime-tracking-overview.png"
  alt="Google Analyticsin reaaliaikaisen raportin yleiskatsaus"
/>

<p>
  Huomaa, että voisit toteuttaa muita työkaluja tai skriptejä ja laukaista ne <strong>kaikilla sivuilla</strong> vain muutamassa minuutissa? <br /> Toivon, että tämä saa sinut ymmärtämään taginhallintajärjestelmän helppouden ja voiman.
</p>

<H as="h2">Kuinka asettaa tavoitteet Google Analyticsissa?</H>
<p>
  Nyt kun saat kävijätietoja, on aika asettaa <strong>Google Analytics -tavoitteet</strong>.
</p>
<p>On todennäköistä, että myyt jotain verkkosivustollasi tai siellä on lomake, jonka ihmiset voivat täyttää. </p>
<p>
  Yleensä tilauksen jälkeen käyttäjät päätyvät <strong>kiitossivulle</strong>. Siinä yleensä kiitetään tilauksesta ja listataan joitakin tilauksen tietoja. <br /> Katso osoiteriville ja näe, mikä URL-osoite sivulla on.{" "}
</p>
<p>Esimerkiksi se voisi näyttää tältä:</p>
<p>
  <code>https://www.yoursite.com/thank-you.php</code>{" "}
</p>
<p>
  Määritetään tavoite tälle osoitteelle ja lasketaan, kuinka usein tätä sivua vieraillaan, jotta saadaan selville, kuinka monta tilausta on tehty.
</p>

<ul>
  <li>
    Mene Google Analyticsin <strong>hallinta-alueelle</strong>, josta näet tilisi, omaisuutesi ja näkymäsi. Klikkaa <strong>tavoitteet</strong> <strong>näkymän</strong> osiossa.
  </li>
</ul>

<ImgScreenshot
  src="google-analytics-setup/google-analytics-admin-area-goals.png"
  alt="Google Analyticsin hallinta-alueen tavoitteet"
  className="article-img"
/>

<ul>
  <li>
    <strong>Lisää</strong> uusi tavoite ja valitse <strong>mukautettu</strong> tavoitteen asetuksissa. Sitten <strong>jatka</strong>.
  </li>
  <li>
    Anna tavoitteellesi <strong>nimi</strong>, esimerkiksi <em>Uutiskirjeen tilaus</em> tai <em>Tilauslomake vastaanotettu</em> ja valitse tyypiksi <strong>kohde</strong>. Sitten <strong>jatka</strong>.
  </li>
  <li>
    <strong>Kohde</strong> kohdassa valitse <strong>on yhtä kuin</strong> ja lisää URL-<strong>polku</strong>, joten esimerkissämme:
    <p>
      <code>/thank-you.php</code>
    </p>
    Voit myös valita <strong>säännöllinen lauseke</strong> vastaavuustyypiksi avattavasta valikosta.
    <br />
    Jos et tiedä, mitä <strong>säännölliset lausekkeet</strong> ovat: Ne ovat tehokas ja kätevä tapa vastata merkkijonoja. Tässä voit oppia niiden käytön tunnissa tai kahdessa:{" "}
    <a href="https://regexone.com/" target="_blank" rel="noopener">
      RegexOne
    </a>
  </li>
  <li>
    Voit halutessasi antaa tavoitteellesi <strong>arvon</strong>. Suosittelen sitä, jos voit antaa numeerisen arvon jokaiselle kiitossivun katselukerralle.
    <br /> On useita tapoja tehdä tämä. Helpoin on katsoa kuukausittaista tuottoa kiitossivuilta ja jakaa se kaikkien sivukatseluiden määrällä.
    <br />
    Tämä antaa sinulle keskiarvon jokaiselle tavoitteen saavuttamiselle.
  </li>
  <li>
    <strong>Aseta suppilo</strong>, jos käyttäjäpolun on tarkoitus käydä läpi tiettyjä sivuja ennen konversiota. Tämä on järkevää lomakkeille, jotka on jaettu useaan vaiheeseen. Voit myöhemmin tarkistaa, putoavatko jotkut kävijät pois suppilosta prosessin aikana. <br />
    Voit lisätä vaiheita suppiloon antamalla jokaiselle vaiheelle <strong>nimen</strong> ja <strong>sivupolun</strong> (<em>katso alla</em>). <br />
    Huomaa, että jos valitsit <strong>on yhtä kuin</strong> yläosassa kohteen avattavasta valikosta, sinun on käytettävä samaa vastaavuustyyppiä suppilon säännöissäsi.
    <br />
    Tai jos valitsit <strong>säännölliset lausekkeet</strong>, sinun on noudatettava regex-syntaksia sivujesi vastaavuussäännöissä.
  </li>
  <li>
    Ennen tallentamista klikkaa <strong>tarkista tavoite</strong>. <br />
    Jos sinulla on jo joitain tietoja GA-omaisuudessasi, se käy ne läpi ja tarkistaa vastaavuudet historiallisista tiedoista. <br />
    Jos sinulla ei ole yhtään vastaavuutta, mutta tiedät, että niitä pitäisi olla, olet todennäköisesti tehnyt virheen sivupolkujen määrittelyssä.
    <br /> Tämä on hyvä paikka tarkistaa säännöt ennen tavoitteen asettamisen viimeistelyä.
  </li>
</ul>

<ImgScreenshot
  src="google-analytics-setup/set-up-google-analytics-goals.png"
  alt="Google Analyticsin tavoitteiden asettaminen"
  className="article-img"
/>

<p>
  Onnittelut! Olet juuri asettanut tavoitteesi ja voit seurata tavoitteiden konversioita eteenpäin! <br />
  Tavoitteet tulisi asettaa verkkosivustosi tärkeimmille konversioille. Jos muut konversiot ovat vähemmän kriittisiä tai niillä ei ehkä ole omaa sivupolkua, voit asettaa Google Analytics -tapahtumia.
  <br />
  Tapahtuman asettamisen jälkeen voit käyttää sitä tavoitteidesi perustana.
</p>

<H as="h2">Aseta tapahtumat Google Analyticsissa</H>
<p>
  <Link to="/fi/tapahtuman-seuranta">Tapahtumien asettaminen Google Analyticsissa</Link> voi näyttää hyvin erilaiselta jokaiselle tapahtumalle. Riippuen vuorovaikutuksesta ja verkkosivuston teknologiasta, on olemassa erilaisia vaiheita. Alla voit löytää luettelon erilaisista tapahtumaseurannan esimerkeistä.
</p>
<H as="h3">Esimerkkejä tapahtumaseurannasta</H>

<ul>
  <li>sisällön jakaminen sosiaalisessa mediassa</li>
  <li>videon toistaminen etusivulla</li>
  <li>takaisin-painikkeen painaminen Google-hakutuloksiin</li>
  <li>vierittäminen artikkelin alaosaan</li>
  <li>yhteyssähköpostin napsauttaminen</li>
  <li>tuotteen lisääminen ostoskoriin</li>
  <li>tuotteen värivaihtoehdon valitseminen</li>
</ul>

<p>
  Vierityksen seurannan määrittäminen eroaa hiiren klikkausten seurannasta. Dynaamisilla verkkosivustoilla HTML-elementit eivät aina ole näkyvissä. Siksi klikkausten seuranta vaatii erilaisen lähestymistavan kuin staattisilla verkkosivuilla. <br />
  Pointti on, että on monia vaikuttavia tekijöitä, joten kirjoitin erillisen tapahtumaseuranta-artikkelin. Siinä käyn yksityiskohtaisesti läpi, vaihe vaiheelta, ja selitän kuinka seurata painikkeiden klikkauksia tai muita elementtejä. Tutustu siihen täällä:
</p>
<p>
  <Link to="/fi/tapahtuman-seuranta">Tapahtumaseuranta Google Analyticsissa</Link>
</p>
<H as="h2">Kampanjaseurannan asettaminen Google Analyticsissa</H>
<p>
  Kampanjaseuranta on jo sisäänrakennettu jokaiseen Google Analytics -asennukseen. Sinun tarvitsee vain lisätä <Link to="/fi/wiki-analytics/utm-parametri">UTM-parametrit</Link> sähköpostin tai sosiaalisen median kampanjoidesi linkkeihin.
</p>
<p>
  On viisi parametria, jotka voit lisätä mihin tahansa sivustollesi osoittavaan linkkiin. Sisäänrakennettu Google Analyticsin kampanjaseuranta tunnistaa käyttäjän tällaisesta linkistä. Se sitten liittää vierailun määriteltyyn kampanjaan ja voit löytää sen kampanjaraportista.
</p>
<p>
  Otetaan URL-osoite <code>https://www.yoursite.com/campaign</code>
  <br />
  ja seuraavat kampanjaseurantaparametrit esimerkiksi:
</p>

<table className="table table-striped table-bordered">
  <thead>
    <tr>
      <th>UTM-parametri</th>
      <th>Parametrin arvo</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>UTM Lähde</td>
      <td>Medium</td>
    </tr>
    <tr>
      <td>UTM Medium</td>
      <td>Vierailija</td>
    </tr>
    <tr>
      <td>UTM Kampanja</td>
      <td>Kevätmyynti</td>
    </tr>
    <tr>
      <td>UTM Termi</td>
      <td>Saappaat</td>
    </tr>
    <tr>
      <td>UTM Sisältö</td>
      <td>Saappaat lämpimällä säällä</td>
    </tr>
  </tbody>
</table>

<p>
  Tämä on <strong>sama URL-osoite UTM-parametrien kanssa</strong>:
</p>
<p>
  <code>
    https://www.yoursite.com/campaign?<strong>UTM_source</strong>
    =medium&amp;<strong>UTM_medium</strong>
    =vierailija&amp;<strong>UTM_campaign</strong>=kevatmyynti&amp;
    <strong>UTM_term</strong>=saappaat&amp;<strong>UTM_content</strong>
    =saappaat-lampimalla-saalla
  </code>
</p>
<p>
  Voit käyttää sitä sosiaalisessa mediassa tai muissa lähteissä, jotka linkittävät sivustoosi. Google Analytics seuraa automaattisesti, kuinka monta klikkausta tavoittaa sinut tällaisesta linkistä.
</p>
<p>
  Huomaa <strong>viisi UTM-parametria</strong> URL-osoitteessa? Sinun ei tarvitse lisätä niitä manuaalisesti. On olemassa 
  <a 
  href="https://ga-dev-tools.appspot.com/campaign-URL-builder/" 
  target="_blank" 
  title="Työkalu UTM-parametreillä varustettujen linkkien luomiseen"
  >
    UTM Builder -työkaluja
  </a> {" "}
  jotka tekevät sen puolestasi. Sinun tarvitsee vain antaa heille <strong>linkki sivustollesi</strong> ja arvot <strong>UTM-parametreille</strong>. Tämän jälkeen ne antavat sinulle kampanjaseurantalinkin, joka sisältää kaikki UTM-parametrit.
</p>
{/* <p>
  Kirjoitin erillisen artikkelin aiheesta {" "}
  <Link href="/fi/kampanjaseuranta">
    UTM-kampanjaseuranta
  </Link>
  , jossa selitän eri UTM-parametrit, miten löytää ne Google Analyticsissa ja kuinka luoda työprosessi kampanjaseurannan käsittelemiseksi säännöllisesti. <br />
  Voit lukea sen täältä:
</p>

<p>
  <Link to="/fi/kampanjaseuranta">
    Kampanjaseuranta Google Analyticsissa
  </Link>
</p>
<H as="h2">Google Analyticsin parhaat käytännöt</H> */}

<H as="h2">Google Analyticsin parhaat käytännöt</H>
<p>
  Alla on luettelo lisätyistä <strong>parhaista käytännöistä</strong> Google Analyticsin asettamiseen. Sovellan jokaista niistä tarkasti jokaisessa GA-asennuksessa, jonka teen. Ne eivät ole vain <em>kiva olla</em>, vaan välttämättömiä maksimoimaan datan laadun. <br />
  Suodattimet voidaan asettaa myös taginhallintajärjestelmässä. Itse asiassa suosin sitä, koska JavaScript mahdollistaa edistyneemmät säännöt suodattamisessa. Siitä huolimatta, Google Analyticsin suodattimet ovat yksinkertaisempia ja hoitavat tehtävän melkein yhtä hyvin.
</p>
<ol start="1">
  <li>
    <H as="h3">Oman liikenteen sulkeminen pois Google Analyticsista</H>
    Nyt kun seuraat vierailuja, sulje omat istuntosi pois verkkosivustosi liikenteestä. Selauskäyttäytymisesi eroaa tavallisista käyttäjistä ja muuten vääristää tietojasi. Tämä on erityisen tärkeää vähäliikenteisillä sivuilla, koska omat sivukatselut painottuvat enemmän.
    <br />
    Sulkeaksesi <strong>oman liikenteesi pois</strong> voit suodattaa itsesi <strong>IP-osoitteen</strong> perusteella, jos sinulla on staattinen IP.
    <br />
    Jos ei, saatat harkita hankkia sellaisen internet-palveluntarjoajaltasi, koska ne eivät yleensä maksa paljon.
  </li>
</ol>
<ul>
  <li>
    Asettaaksesi <strong>IP-osoitteen poissulkemisen Google Analyticsissa</strong>, mene <strong>hallintapaneeliin</strong> ja klikkaa <strong>Suodattimet</strong> oikeanpuoleisessa <strong>näkymä</strong>-sarakkeessa.
  </li>
  <li>
    Lisää <strong>nimi</strong> suodattimellesi ja valitse <strong>suodattimen tyyppi</strong> <strong>mukautettu</strong>.
  </li>
  <li>
    Valitse <strong>poissulkeminen</strong> suodatinvaihtoehdoksi ja valitse <strong>IP-osoite</strong> avattavasta valikosta.
  </li>
  <li>
    Syötä sitten staattinen IP-osoitteesi <strong>suodatuskuvio</strong>-kenttään ja klikkaa <strong>tallenna</strong>.
  </li>
</ul>

<ImgContainerFixed width="415px">
  <ImgScreenshot
    src="google-analytics-setup/exclude-ip-from-google-analytics-setup.png"
    alt="IP-osoitteen poissulkeminen Google Analyticsista asennuskuvakaappaus"
    className="article-img"
  />
</ImgContainerFixed>

<ol start="2">
  <li>
    <H as="h3">Sivupolkujen muuttaminen pieniksi kirjaimiksi</H>
    Toinen säätö on <strong>muuttaa sivupolkusi automaattisesti pieniksi kirjaimiksi</strong>.
    <br />
    Ihmiset tekevät virheitä ja jossain vaiheessa joku luo URL-osoitteen, jonka nimessä on isoja kirjaimia. Jos verkkosivusto sitten palauttaa saman sisällön, kuin samalle URL-osoitteelle pienillä kirjaimilla, asiat menevät sekaisin. <br /> Selitän. <br />
    Lähetämme olennaisesti dataa kahden eri sivupolun alla, vaikka data on samalle sivulle.
    <br />
    Eri tavoin kirjoitetut sivupolut johtavat sekavaan analyysiin Google Analyticsissa. Mikä tahansa ainutlaatuinen tapa kirjoittaa sama polku näytetään omalla rivillään raporteissa. Jonkin ajan kuluttua, päädyt tarkastelemaan useita rivejä ja niiden mittareita, vaikka niiden pitäisi olla yhdistetty. <br />
    Jos saat sitten myös paluuviittauksia samalle sivulle, mutta linkin osoite oli kirjoitettu oudolla tavalla, sekasotku on täydellinen. Yritetään pitää elämämme draamavapaana ja korjata tämä etukäteen.
  </li>
</ol>
<ul>
  <li>
    Muuttaaksesi <strong>URL-polut pieniksi kirjaimiksi</strong> käy <strong>Suodattimet</strong> näkymässä uudelleen ja lisää uusi suodatin. Anna sille nimi ja valitse <strong>mukautettu</strong> suodatin tyyppi.
  </li>
  <li>
    Valitse nyt <strong>Pienet kirjaimet</strong> ja valitse <strong>Request URI</strong> kenttävalikosta.
  </li>
</ul>

<ImgContainerFixed width="369px">
  <ImgScreenshot
    src="google-analytics-setup/set-up-lowercase-urls-in-google-analytics.png"
    alt="Määritä pienet kirjaimet URL-osoitteissa Google Analyticsissa"
    className="article-img"
  />
</ImgContainerFixed><br />

<ul>
  <li>
    Klikkaa <strong>tallenna</strong> viimeistelläksesi. Helppo korjaus, joka säästää monilta päänsäryiltä.
  </li>
</ul>
<ol start="3">
  <li>
    <H as="h3">Google Analytics -näkymän rakenne</H>
    <p>
      <strong>Näkymä</strong> on paikka, jossa pääset käsiksi kaikkiin Google Analytics -raportteihisi. Jos näkymääsi on asetettu suodattimia, kaikki raportit suodatetaan sen mukaisesti.
      <br />
      <strong>Näkymän rakenne</strong> tulisi yleensä jäljitellä organisaatiosi rakennetta. Jos kohdemarkkinasi ovat esimerkiksi alueellisia eikä maakohtaisia, aseta näkymät samalla tavalla. Se on kätevää, kun alueiden KPI:t ovat suoraan käytettävissä.
      <br />
      Toinen suuntautuminen on organisaatiosi rakenne budjetoinnin ja markkinointitoimien osalta. Jos se tehdään maakohtaisesti, on todennäköisesti hyvä idea noudattaa tätä rakennetta myös näkymissä.
      <br />
      Voit luoda enintään 25 näkymää per omaisuus. Joten markkinoiden ja markkinointikanavien lukumäärästä riippuen, useita näkymärakenteita on mahdollista.
    </p>
    <H as="h3">Esimerkki: Google Analytics -näkymärakenne paikallisille verkkosivustoille</H>
    <p>
      1.0 Master
      <br />
      2.0 Sisäinen liikenne
      <br />
      3.0 Raakadata
    </p>
    <p>
      Jollain tavalla, näkymiä on yleensä 3 tyyppiä. Niitä voitaisiin suodattaa edelleen maittain tai laitteittain, jos se on järkevää organisaatiollesi.
    </p>
    <H as="h3">Esimerkki: Google Analytics -näkymärakenne monikansallisille verkkosivustoille</H>
    <p>
      1.1 Master (US)
      <br />
      1.2 Master (UK)
      <br />
      1.3 Master (DE)
      <br />
      1.4 Master (FR)
      <br />
      1.5 Master (ES)
      <br />
      1.6 Master (MX)
      <br />
      1.7 Master (RUS)
      <br />
      2.0 Sisäinen liikenne
      <br />
      3.0 Raakadata
    </p>

    <H as="h3">Esimerkki: Google Analytics -alueellinen näkymärakenne</H>
    <p>
      1.0 Master (NA)
      <br />
      1.1 Master (EMEA)
      <br />
      1.2 Master (LATAM)
      <br />
      1.3 Master (APEC)
      <br />
      2.0 Sisäinen liikenne
      <br />
      3.0 Raakadata
    </p>

    <p>
      <strong>Master-näkymä</strong> on ensisijainen analyysinäkymä ja sulkee pois kaiken sisäisen liikenteen.
    </p>
    <p>
      <strong>Sisäinen liikennenäkymä</strong> sisältää kaiken sisäisen liikenteen. Tätä voidaan käyttää myös virheenkorjaus- ja testitarkoituksiin. Jos asetat sisällytyssuodattimen, kaikki muu suljetaan automaattisesti pois.
    </p>
    <p>
      Alussa Google Analytics luo ensimmäisen <strong>All Web Site Data -näkymän</strong> yhdessä GA-omaisuuden kanssa.
    </p>
    <p>
      Pidä se, mutta vaihda nimi <strong>Raakadata</strong>. Haluat säilyttää raakadata-näkymän ilman suodattimia. Et voi peruuttaa suodatinta jälkikäteen ja tarkastella suodattamatonta dataa. Siksi on paras käytäntö pitää yksi näkymä koskemattomana. Vaihtaaksesi nimen, mene <strong>Admin &gt; View &gt; View Settings</strong>.
    </p>
    <p>
  Useimmissa tapauksissa yritykset keskittävät ponnistelunsa yhteen tai muutamaan maahan. Tällöin luo näkymiä maakohtaisesti ja aseta suodattimet sen mukaisesti.
  <br />
  Voit myös luoda näkymiä liikenteen lähteen mukaan (orgaaninen, maksettu, suora, sosiaalinen, jne.) tai laitteen mukaan (pöytäkone, tabletti, mobiili). Käytän siihen Google Analyticsin segmenttejä, mutta ei ole syytä olla suodattamatta yksityiskohtia jo näkymässä. Haluan pitää näkymien määrän vähäisenä, koska se pitää GA-käyttöliittymän siistinä.
  <br />Voin sitten sekoittaa segmenttejä näkymässä ja soveltaa jäljellä olevia yksityiskohtia siellä.
</p>
<p>
  Luo kaikki näkymät mahdollisimman aikaisin, koska ne näyttävät tietoja vain siitä hetkestä lähtien, kun ne on luotu. Uusi näkymä ei näytä historiallisia tietoja ennen luomispäivää. Jos sinun täytyy tarkastella historiaa tietyillä suodattimilla, käytä raakadata-näkymää. Käytä vain tarkoitettuja suodattimia segmenttien avulla. Näin saat tarvittavan näkemyksen, mutta et muuta dataa.
</p>
<p>
  <strong>VINKKI</strong>: Jos jotkin näkymät jakavat samat suodattimet, voit luoda yhden ja sitten kopioida sen kaikkien suodattimien kanssa. Lisää sen jälkeen näkymäkohtaiset suodattimet kopioihin.
  <br />
  Varmista kuitenkin, että alkuperäinen näkymä pysyy muuttumattomana, joten lisää suodattimia vain kopioihin. Alkuperäisen näkymän muuttaminen myöhemmin vaikuttaa myös kopioituihin näkymiin.
</p>
</li>
<li>
<H as="h3">Google Analytics -hälytykset</H>
Toinen paras käytäntö on <strong>asettaa Google Analytics -hälytykset</strong>. Asetan sen ilmoittamaan, jos istunnot vähenevät tai jos palvelimen vasteaika nousee. Näin voin tutkia syitä ja mahdollisesti tehdä jotain asialle.
</li>
</ol>
<ul>
  <li>
    Asettaaksesi hälytyksen Google Analyticsissa, käy <strong>Muokkaus</strong> sivuvalikossa.
  </li>
  <li>
    Vaihtoehtoisesti voit myös käydä uudelleen <strong>Hallinta</strong> alueella ja klikata <strong>Mukautetut hälytykset</strong> näkymäruudussa.
  </li>
  <li>
    Klikkaa <strong>Mukautetut hälytykset</strong> ja klikkaa <strong>hallinnoi mukautettuja hälytyksiä</strong>
  </li>
  <li>
    Google Analytics -hälytyksen määrittäminen istuntojen vähenemiselle on melko yksinkertaista. Klikkaa <strong>Uusi hälytys</strong> ja nimeä hälytys esimerkiksi <strong>Istuntojen väheneminen</strong> ja aseta <strong>Ajanjakso</strong> kohdaksi <strong>Viikko</strong>.
  </li>
  <li>Valitse myös ruutu, jotta saat sähköpostin, kun hälytys laukeaa.</li> 
  <li>
    Liikenteessä on yleensä vaihtelua arkipäivien, viikonloppujen ja kansallisten vapaapäivien välillä. Emme halua hälytyksiä näistä vaihteluista, joten asetamme sen tarkastelemaan viikoittaisia istuntotietoja. 
  </li>
  <li>
    <strong>Hälytysehdot</strong> koskevat <strong>kaikkea liikennettä</strong> ja haluat saada hälytyksen, kun <strong>istunnot vähenevät yli 35% edelliseen viikkoon verrattuna.</strong>
  </li>
</ul>

<ImgScreenshot
  src="google-analytics-setup/set-up-google-analytics-alert-session-decrease.png"
  alt="aseta Google Analytics -hälytykset istuntojen vähenemiselle kuvakaappaus"
  className="article-img"
/>

<p>
  Tämän jälkeen paina <strong>tallenna</strong> ja lisätään seuraava hälytys.
</p>
<p>Asettaaksesi Google Analytics -hälytyksen palvelimen vasteajan kasvulle,</p>
<ul>
  <li>
    klikkaa <strong>Uusi hälytys</strong> ja anna sille nimi, kuten <em>Palvelimen vasteajan kasvu päivä päivältä</em> ja
  </li>
  <li>
    valitse <strong>päivä</strong> ajanjaksoksi.
  </li>
  <li>
    <strong>Hälytysehdot</strong> koskevat <strong>kaikkea liikennettä</strong> ja haluat saada hälytyksen, kun <strong>keskiarvoinen palvelimen vasteaika kasvaa yli 300%</strong> verrattuna <strong>edelliseen päivään</strong>:
  </li>
</ul>

<ImgScreenshot
  src="google-analytics-setup/set-up-alerts-server-rsp-time-google-analytics.png"
  alt="aseta Google Analytics -hälytykset palvelimen vasteajalle kuvakaappaus"
  className="article-img"
/>

<p>
  Tämän jälkeen paina <strong>tallenna</strong>.<br />
  Nyt voit olla varma, että saat hälytyssähköpostin, kun liikenteesi vähenee tai kun palvelimellasi on ongelmia.
</p>
</MainContent>
</React.Fragment>

</Layout>
);

export default AnalyticsSetup;
